import React, { useCallback, useContext, useEffect, useRef, useState, useMemo } from 'react'
import debounce from "lodash/debounce";
import { Button, Col, Modal, Row } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import { AgGridReact } from 'ag-grid-react';
import { getProductDropDownAction } from '../../redux/actions/productAction';
import { useLocation } from 'react-router-dom';
import { ADD_FINAL_MATERIAL, ADD_FINAL_MATERIAL_SUB_PRODUCT, DELETE_FINAL_MATERIAL, EDIT_FINAL_MATERIAL, GET_FINAL_MATERIAL_BY_PARANT_ID } from '../../redux/type';
import { Paginationlimit, Unit, UserRoles } from '../../helper/Constant';
import Select from 'react-select';
import { addFinalMaterial, deleteFinalMaterial, editFinalMaterial, getFinalMaterialByProject } from '../../redux/actions/finalMaterial';
import { RoleContext } from '../../helper/RoleProvider';
import editIcon from '../../assets/image/edit.svg';
import removeImg from '../../assets/image/delete-red.png'
import AuthStorage from '../../helper/AuthStorage';
import STORAGEKEY from '../../config/APP/app.config';
import SweetAlert from 'react-bootstrap-sweetalert';
import Plusicon from "../../assets/image/plus.png";
import "ag-grid-community/dist/styles/ag-theme-balham-dark.css";

import usePaginationHooks from '../../Hooks/paginationHooks';
import { ApiGet, ApiPut } from '../../helper/API/ApiData';
import SubProduct from './SubProduct';
import DeatailsComponent from './Details';
// import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import "ag-grid-enterprise";

import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-material.css";
import { getProjectByIdAction } from '../../redux/actions/projectAction';
import img from "../../assets/image/select.png";

const FinalMaterial = ({ show, onHide, status, isFinalMaterialConfirmed }) => {

    const dispatch = useDispatch()
    const location = useLocation()
    const { getFinalMaterialByProjects, getAllProducts } = usePaginationHooks()
    const queryParams = new URLSearchParams(location.search)
    const projectId = queryParams.get("projectId")
    const userRole = useContext(RoleContext);
    const RolesForAdd = [UserRoles.Admin, UserRoles.Project]
    const [columnDef, setColumnDefs] = useState([]);
    const [isSubproduct, setIsSubproduct] = useState({
        open: false,
        mainProdQty: '',
        parantId: '',
        productId: '',
        _id: ''
    })
    const [userData, setUserData] = useState([]);
    const [selectedProduct, setSelectedProduct] = useState(null);
    const [isopen, setIsopen] = useState(false)
    const [limit, setlimit] = useState(Paginationlimit);
    const [total, settotal] = useState(0)
    const [totalRSP, settotalRSP] = useState(0)
    const [confirmTog, setConfirmTog] = useState(false)
    const [totalLC, setTotalLC] = useState(0)
    const [totalBP, setTotalBP] = useState(0)
    const [isFinalMaterialConfirmedd, setisFinalMaterialConfirmed] = useState(isFinalMaterialConfirmed)
    const [gridApi, setGridApi] = useState(null);
    const usrData = AuthStorage.getStorageJsonData(STORAGEKEY.userData)
    const [getFinalMaterialByProjectData, setgetFinalMaterialByProjectData] = useState([])
    const [deletepopup, setDeletepopup] = useState(false);
    const [paramsValue, setParamsValue] = useState();
    const [editId, setEditId] = useState('')
    const [productReq, setProductReq] = useState({
        productId: '',
        productName: '',
        productCode: '',
        reqQuantity: '',
        desc: '',
        make: '',
        buyingPrice: '',
        editedBuyingPrice: '',
        retailSalePrice: '',
        labourCharge: '',
        unit: '',
        showQty: 0,
        remark: ''
    });
    const [sorting, setSorting] = useState({ column_name: '', column_order: '' });
    const [filter, setFilter] = useState({ search: "" });
    const [search, setSearch] = useState("");
    const [rotateArrow, setRotateArrow] = useState('');
    const addToast = () => toast.success("Final Material Added successfully");
    const toastSuccess = () => toast.success('Final material deleted successfully');
    const editToast = () => toast.success('Final material edited successfully');
    const [formErrors, setFormErrors] = useState()
    const [productOption, setProductOption] = useState([])
    const addFinalMaterialData = useSelector((state) => state.add_Final_Material.addFinalMaterial)
    const addFinalMaterialSubProduct = useSelector((state) => state.add_Final_Material_Sub_product.addFinalMaterialSubProduct)
    const deleteFinalMaterialData = useSelector((state) => state.delete_Final_Material.deleteFinalMaterial)
    const editFinalMaterialData = useSelector((state) => state.edit_Final_Material.editFinalMaterial)
    const productData = useSelector((state) => state.get_Product_Drop_Down.getProductDropDown.data)
    // const getFinalMaterialByProjectData = useSelector((state) => state.get_Final_Material_By_Project.getFinalMaterialByProject.data)

    const unitOption = [
        { value: Unit.KG, label: Unit.KG },
        { value: Unit.LTR, label: Unit.LTR },
        { value: Unit.NOS, label: Unit.NOS },
        { value: Unit.MTR, label: Unit.MTR },
        { value: Unit.FEET, label: Unit.FEET },
    ]

    useEffect(() => {
        dispatch(getProductDropDownAction())
    }, [])

    useEffect(() => {
        const totalBP = getFinalMaterialByProjectData?.data?.length ? getFinalMaterialByProjectData?.data.reduce((accumulator, curr) => accumulator + (curr.buyingPrice * curr.quantity) + curr.subProduct.reduce((acc2, curr2) => acc2 + (curr2.buyingPrice * curr2.quantity), 0), 0) : 0
        const totalLC = getFinalMaterialByProjectData?.data?.length ? getFinalMaterialByProjectData?.data.reduce((accumulator, curr) => accumulator + (curr.labourCharge * curr.quantity) + curr.subProduct.reduce((acc2, curr2) => acc2 + (curr2.labourCharge * curr2.quantity), 0), 0) : 0
        const totalRSP = getFinalMaterialByProjectData?.data?.length ? getFinalMaterialByProjectData?.data.reduce((accumulator, curr) => accumulator + (curr.retailSalePrice * curr.quantity)+ curr.subProduct.reduce((acc2, curr2) => acc2 + (curr2.retailSalePrice * curr2.quantity), 0), 0) : 0
        setTotalBP(totalBP)
        setTotalLC(totalLC)
        settotalRSP(totalRSP)
        settotal(totalRSP - (totalBP + totalLC))
    }, [getFinalMaterialByProjectData])

    // useEffect(() => {
    //     if (projectId) {
    //         dispatch(getFinalMaterialByProject(projectId))
    //     }
    // }, [projectId])

    useEffect(() => {
        if (getFinalMaterialByProjectData) {
            if (RolesForAdd.includes(userRole)) {
                setColumnDefs(columnDefs)
            } else {
                setColumnDefs(NoRolecolumnDefs)
            }
            // setUserData(getFinalMaterialByProjectData.map((ele) => {
            //     return {
            //         productId: ele.productId ? ele.productId : '',
            //         productName: ele.productName ? ele.productName : '',
            //         quantity: ele.quantity ? ele.quantity : '',
            //         desc: ele.desc ? ele.desc : '',
            //         make: ele.make ? ele.make : '',
            //         unit: ele.unit ? ele.unit : '',
            //         createdBy: ele.createdBy ? ele.createdBy : '',
            //         _id: ele._id ? ele._id : '',
            //     }
            // }))
        }
    }, [getFinalMaterialByProjectData])

    useEffect(() => {
        if (addFinalMaterialSubProduct && addFinalMaterialSubProduct.data && addFinalMaterialSubProduct.status === 200) {
            toast.success(addFinalMaterialSubProduct.message);
            dispatch({
                type: ADD_FINAL_MATERIAL_SUB_PRODUCT,
                payload: []
            });
        }
    }, [addFinalMaterialSubProduct])

    const CustomHeaderComponent = (props) => {
        return (
            <div>
                {/* <span >{props.displayName}</span> */}

                {isopen ? <span title='Collapse All' className='ms-2' style={{ cursor: 'pointer' }} onClick={() => {
                    gridApi.api?.forEachNode((node) => {
                        if (node.rowIndex !== null) {
                            gridApi.api?.getDisplayedRowAtIndex(node.rowIndex)?.setExpanded(false);
                        }
                    });
                    setIsopen(!isopen)
                }}>▲</span>
                    : <span title='Expalnd All' className='ms-2' style={{ cursor: 'pointer' }} onClick={() => {
                        gridApi.api?.forEachNode((node) => {
                            if (node.rowIndex !== null) {
                                gridApi.api?.getDisplayedRowAtIndex(node.rowIndex)?.setExpanded(true);
                            }
                        });
                        setIsopen(!isopen)
                    }}>▼</span>}

            </div>
        );
    };

    const columnDefs = [
        {
            headerName: 'Collapse All',
            field: 'index',
            maxWidth: 80,
            cellRenderer: "agGroupCellRenderer",
            headerComponentFramework: CustomHeaderComponent,
        },
        {
            headerName: 'Product',
            field: 'productName',
            flex: 3,
        },
        { field: 'quantity', maxWidth: 80 },
        { field: 'unit', maxWidth: 80 },
        // { field: 'buyingPrice', minWidth: 120 },
        { field: 'retailSalePrice', maxWidth: 80, headerName: "RSP" },
        { field: 'buyingPrice', maxWidth: 80, headerName: "BP" },
        { field: 'labourCharge', maxWidth: 60, headerName: "LC" },
        // { field: 'TotalFinalMaterial', headerName: "Total", minWidth: 120 },
        { field: 'Total', headerName: 'Grand Total', maxWidth: 100 },
        { field: 'make', maxWidth: 100 },
        // { field: 'desc', headerName: 'Description', minWidth: 120 },
        // {
        //     field: "createdBy",
        //     minWidth: 120,
        //     cellRendererFramework: (params) => <div>
        //         {params?.value?.name}
        //     </div>
        // },
        { field: 'remark', headerName: 'Remark', maxWidth: 150 },
        ((userRole === UserRoles.Admin || status !== "Active") && {
            headerName: 'Action',
            field: "_id",
            sortable: false,
            flex: 1,
            cellRendererFramework: (params) =>
                <div>
                    {/* {(params.data?.createdBy?._id === usrData.id || userRole === UserRoles.Admin || params.data?.projectManager._id === usrData.id) &&
                        <><img src={editIcon} style={{ cursor: "pointer", height: "20px" }} onClick={() => onEdit(params.data?._id)} title="Edit" alt='' /></>} */}
                    {(userRole === UserRoles.Admin || params.data?.createdBy?._id === usrData.id) && <img src={removeImg} onClick={() => { setDeletepopup(true); setParamsValue(params?.value) }} style={{ cursor: "pointer", height: "20px" }} className='ms-3' title="Delete" alt='' />}
                    {<img src={Plusicon} onClick={() => { setIsSubproduct({ open: true, parantId: params?.value, productId: params.data.productId._id, _id: '', mainProdQty: params.data?.quantity }) }} style={{ cursor: "pointer", height: "20px" }} className='ms-3' title="Add" alt='' />}
                </div>
        })
    ]

    const NoRolecolumnDefs = [
        { field: 'productName', flex: 1 },
        { field: 'quantity', maxWidth: 80 },
        { field: 'unit', maxWidth: 80 },
        { field: 'make', maxWidth: 100 },
        // { field: 'desc', headerName: 'Description', minWidth: 120 },
        // {
        //     field: "createdBy",
        //     minWidth: 120,
        //     cellRendererFramework: (params) => <div>
        //         {params?.value?.name}
        //     </div>
        // },
        { field: 'remark', headerName: 'Remark', maxWidth: 150 },
    ]

    const defaultColDef = React.useMemo(() => {
        return {
            resizable: false,
            sortable: true,
            width: 'auto',
            filter: true,
            flex: 1,
        };
    }, []);

    const handleRowClick = (params) => {
        if (params.colDef.field !== "_id") {
            return onEdit(params.data._id);
        }
    }

    const onEdit = (id) => {
        setEditId(id)
        let EditData = getFinalMaterialByProjectData.data.find((ele) => ele._id === id)
        const data = productData?.find((ele) => ele?._id === EditData.productId?._id)
        if (EditData) {
            setProductReq({
                productId: EditData.productId?._id,
                productName: EditData.productName,
                productCode: EditData.productCode,
                reqQuantity: EditData.quantity,
                desc: EditData.desc,
                buyingPrice: EditData.buyingPrice,
                editedBuyingPrice: EditData.buyingPrice,
                labourCharge: EditData.labourCharge ? EditData.labourCharge : '',
                retailSalePrice: EditData.retailSalePrice,
                make: EditData.make,
                unit: EditData.unit,
                showQty: data?.stock,
                remark: "Manually Added"

            })
        }
    }

    useEffect(() => {
        if (productData) {
            let temp = productData.sort((a, b) => a.productName.localeCompare(b.productName, undefined, { case: 'upper' }))
            setProductOption(temp.map((ele) => {
                return {
                    value: ele._id,
                    label: ele.productCode ? `${ele.productName}` : ele.productName
                }
            }))
        }
    }, [productData])

    const validation = () => {
        let flag = false;
        const error = {};
        if (!productReq.productId) {
            error.productId = 'Required'
            flag = true;
        }
        if (!productReq.reqQuantity) {
            error.reqQuantity = 'Required'
            flag = true;
        }
        else if (productReq.reqQuantity == 0) {
            error.reqQuantity = 'Not valid'
            flag = true;
        }
        if (!productReq.unit) {
            error.unit = 'Required'
            flag = true;
        }
        if (!productReq.retailSalePrice) {
            error.retailSalePrice = 'Required'
            flag = true;
        }
        // if (!productReq.labourCharge) {
        //     error.labourCharge = 'Required'
        //     flag = true;
        // }
        setFormErrors(error);
        return flag;
    };

    const Save = () => {
        if (validation()) {
            return
        }
        const body = {
            project: projectId,
            productId: productReq.productId,
            productName: productData.find((ele) => ele._id === productReq.productId).productName,
            // productName: productReq.productName,
            quantity: parseInt(productReq.reqQuantity),
            desc: productReq.desc,
            make: productReq.make,
            unit: productReq.unit,
            remark: "Manually Added",
            buyingPrice: +productReq.editedBuyingPrice,
            labourCharge: +productReq.labourCharge,
            retailSalePrice: +productReq.retailSalePrice,
        }
        dispatch(addFinalMaterial(body))
    }

    useEffect(() => {
        if (addFinalMaterialData && addFinalMaterialData.data && projectId) {
            if (addFinalMaterialData.status === 200) {
                dispatch({
                    type: ADD_FINAL_MATERIAL,
                    payload: []
                })
                addToast(true)
                // dispatch(getFinalMaterialByProject(projectId))
                setProductReq({
                    productId: '',
                    productName: '',
                    productCode: '',
                    reqQuantity: '',
                    labourCharge: '',
                    retailSalePrice: '',
                    buyingPrice: '',
                    editedBuyingPrice: '',
                    desc: '',
                    make: '',
                    unit: '',
                    showQty: 0,
                    remark: ''
                })
            }
        }
    }, [addFinalMaterialData])

    const Edit = () => {
        if (validation()) {
            return
        }
        const body = {
            id: editId,
            project: projectId,
            productId: productReq.productId,
            productName: productData.find((ele) => ele._id === productReq.productId)?.productName,
            // productName: productReq.productName,
            quantity: productReq.reqQuantity,
            desc: productReq.desc,
            make: productReq.make,
            unit: productReq.unit,
            remark: "Manually Added",
            buyingPrice: +productReq.editedBuyingPrice,
            labourCharge: +productReq.labourCharge,
            retailSalePrice: +productReq.retailSalePrice,

        }
        dispatch(editFinalMaterial(body))
    }

    useEffect(() => {
        if (editFinalMaterialData && editFinalMaterialData.data && projectId) {
            if (editFinalMaterialData.status === 200) {
                dispatch({
                    type: EDIT_FINAL_MATERIAL,
                    payload: []
                })
                editToast(true)
                setEditId('')
                // dispatch(getFinalMaterialByProject(projectId))
                setProductReq({
                    productId: '',
                    productName: '',
                    productCode: '',
                    reqQuantity: '',
                    desc: '',
                    labourCharge: '',
                    retailSalePrice: '',
                    buyingPrice: '',
                    editedBuyingPrice: '',
                    make: '',
                    unit: '',
                    remark: '',
                    showQty: 0
                })
            }
        }
    }, [editFinalMaterialData])

    const handleChange = (e, name) => {
        setFormErrors({ ...formErrors, [name]: '' })
        let regex = /^(?:\d*\.\d{0,2}|\d+)$/;
        if (name === 'productId') {

            let selectedProducts = !editId ? getFinalMaterialByProjectData?.data?.map(item => item.productId?._id) : [];
            if (!selectedProducts?.includes(e.value)) {
                const data = productData?.find((ele) => ele?._id === e.value)
                setProductReq({
                    ...productReq,
                    productId: e.value,
                    productName: e.label,
                    showQty: productData?.find((ele) => ele?._id === e.value)?.stock,
                    productCode: data?.productCode,
                    showQty: data?.stock,
                    unit: data?.unitOfMeasurement,
                    buyingPrice: data.buyingPrice,
                    editedBuyingPrice: data.buyingPrice,
                    retailSalePrice: data.retailSalePrice,
                    labourCharge: data.labourCharge ? data.labourCharge : '',
                })
            } else {
                toast.error("You already selected this Product!")
                setProductReq({
                    ...productReq,
                    productId: "",
                    productName: "",
                    productCode: "",
                    showQty: "",
                })
            }
        } else if (name === "reqQuantity") {
            if (e.target.value === "" || regex.test(e.target.value)) {
                setProductReq({
                    ...productReq,
                    reqQuantity: e.target.value
                })
            }
        }
        else if (name === "retailSalePrice") {
            if (e.target.value === "" || regex.test(e.target.value)) {
                setProductReq({
                    ...productReq,
                    retailSalePrice: e.target.value
                })
            }
        }
        else if (name === "labourCharge") {
            if (e.target.value === "" || regex.test(e.target.value)) {
                setProductReq({
                    ...productReq,
                    labourCharge: e.target.value
                })
            }
        }
        else if (name === "buyingPrice") {
            if (e.target.value === "" || regex.test(e.target.value)) {
                setProductReq({
                    ...productReq,
                    editedBuyingPrice: e.target.value
                })
            }
        } else if (name === "desc") {
            setProductReq({
                ...productReq,
                desc: e.target.value
            })
        } else if (name === "make") {
            setProductReq({
                ...productReq,
                make: e.target.value
            })
        } else if (name === "unit") {
            setProductReq({
                ...productReq,
                unit: e.value
            })
        }
    };

    const selectValue = (value, name) => {
        if (name === "unit") {
            return unitOption.find((ele) => ele.value === value)
        } else if (name === "productId") {
            return productOption.find((ele) => ele.value === value)
        }
    }

    const onDelete = (id) => {
        dispatch(deleteFinalMaterial(id))
    }

    useEffect(() => {
        if (deleteFinalMaterialData.status === 200 && projectId) {
            // dispatch(getFinalMaterialByProject(projectId))
            dispatch({
                type: DELETE_FINAL_MATERIAL,
                payload: []
            })
            setDeletepopup(false)
            toastSuccess(true)
        }
    }, [deleteFinalMaterialData])

    const onGridReady = (params) => {
        setGridApi(params);
    };

    useEffect(() => {
        return () => {
            dispatch({
                type: GET_FINAL_MATERIAL_BY_PARANT_ID,
                payload: []
            })
        }
    }, [])

    const debouncedHandleSearch = useCallback(debounce((value) => {
        setFilter({ ...filter, ["search"]: value });
    }, 1000), []);

    useEffect(() => {
        debouncedHandleSearch(search);
    }, [search]);

    useEffect(() => {
        if (gridApi || editFinalMaterialData.status === 200 || addFinalMaterialData.status === 200 || deleteFinalMaterialData.status === 200 || addFinalMaterialSubProduct.status === 200 || filter.search) {
            const dataSource = {
                getRows: (params) => {
                    const page = params.endRow / limit;
                    const sorted_column = sorting.column_name;
                    const sorted_order = sorting.column_order;

                    getFinalMaterialByProjects(projectId, page ? page : 1, limit, filter.search, sorted_column, sorted_order)
                        .then(res => {
                            setgetFinalMaterialByProjectData(res.data)
                            params.successCallback(res?.data?.data ? res.data.data.map((ele, i) => {
                                const subproduct = ele.subProduct.length ? ele.subProduct.map((ele) => {
                                    return {
                                        ...ele,
                                        total: ele.quantity * ((ele.retailSalePrice ? ele.retailSalePrice : 0) + (ele.labourCharge ? ele.labourCharge : 0))
                                    }
                                }) : []
                                const totalMainProduct = ele.quantity * (ele.retailSalePrice + ele.labourCharge)
                                const totalSubProduct = subproduct?.length ? subproduct.reduce((accumulator, curr) => accumulator + curr.total, 0) : 0
                                return {
                                    index: i + 1,
                                    productId: ele.productId ? ele.productId : '-',
                                    productName: ele.productName ? ele.productName : '-',
                                    quantity: ele.quantity ? ele.quantity : '-',
                                    desc: ele.desc ? ele.desc : '-',
                                    make: ele.make ? ele.make : '-',
                                    buyingPrice: ele.buyingPrice ? ele.buyingPrice : '-',
                                    retailSalePrice: ele.retailSalePrice ? ele.retailSalePrice : '-',
                                    labourCharge: ele.labourCharge ? ele.labourCharge : '-',
                                    unit: ele.unit ? ele.unit : '-',
                                    remark: ele.unit ? ele.remark : '-',
                                    createdBy: ele.createdBy ? ele.createdBy : '-',
                                    _id: ele._id ? ele._id : '-',
                                    TotalFinalMaterial: totalMainProduct ? totalMainProduct : '-',
                                    Total: (totalMainProduct + totalSubProduct) ?? '-',
                                    subProduct: subproduct ? subproduct : []
                                }
                            }) : [], res.totalCount ? res.totalCount : 0)

                            if (res?.data?.data) {
                                gridApi?.api?.hideOverlay();
                            } else {
                                gridApi?.api?.showNoRowsOverlay();
                            }
                        })
                }
            };
            // const serverSideDatasource = {
            //     getRows: (params) => dataSource.getRows(params),
            // };
            // gridApi?.api?.setServerSideDatasource(serverSideDatasource);
            gridApi?.api?.setDatasource(dataSource);
        }

    }, [gridApi, projectId, addFinalMaterialData, editFinalMaterialData, deleteFinalMaterialData, limit, addFinalMaterialSubProduct, sorting, filter.search]);

    const ConfirmFinalmaterial = () => {
        const body = {
            id: projectId,
            isFinalMaterialConfirmed: true
        }
        ApiPut(`project/edit_project_Confirm_final_material`, body).then(res => {
            if (res.data.status === 200) {
                dispatch(getProjectByIdAction(projectId))
                toast.success(res.data.message)
                setConfirmTog(false)
                setisFinalMaterialConfirmed(res.data.data.isFinalMaterialConfirmed)
            }

        })
    }

    const onFirstDataRendered = (e) => {
        const row2 = gridApi.api?.getDisplayedRowAtIndex(null);
        row2?.setExpanded(true);
    };

    const detailCellRendererParams = useMemo(() => {
        return {
            detailGridOptions: {
                columnDefs: [
                    {
                        field: 'productName',
                        sortable: true,
                        filter: 'agTextColumnFilter', // Enable text filter
                        filterParams: {
                            filterOptions: ['contains', 'notContains', 'startsWith', 'endsWith'], // Choose filter options
                            suppressAndOrCondition: true, // Use AND condition for multiple filters
                        },
                        flex: 3,
                    },
                    {
                        field: 'quantity',
                        sortable: true,
                        filter: 'agTextColumnFilter', // Enable text filter
                        filterParams: {
                            filterOptions: ['contains', 'notContains', 'startsWith', 'endsWith'], // Choose filter options
                            suppressAndOrCondition: true, // Use AND condition for multiple filters
                        },
                        maxWidth: 80,
                    },
                    {
                        field: 'unit',
                        sortable: true,
                        filter: 'agTextColumnFilter', // Enable text filter
                        filterParams: {
                            filterOptions: ['contains', 'notContains', 'startsWith', 'endsWith'], // Choose filter options
                            suppressAndOrCondition: true, // Use AND condition for multiple filters
                        },
                        maxWidth: 80,
                    },
                    // {
                    //     field: 'buyingPrice',
                    //     sortable: true,
                    //     filter: 'agTextColumnFilter', // Enable text filter
                    //     filterParams: {
                    //         filterOptions: ['contains', 'notContains', 'startsWith', 'endsWith'], // Choose filter options
                    //         suppressAndOrCondition: true, // Use AND condition for multiple filters
                    //     },
                    // },
                    {
                        field: 'retailSalePrice',
                        sortable: true,
                        filter: 'agTextColumnFilter', // Enable text filter
                        filterParams: {
                            filterOptions: ['contains', 'notContains', 'startsWith', 'endsWith'], // Choose filter options
                            suppressAndOrCondition: true, // Use AND condition for multiple filters
                        },
                        maxWidth: 80,
                        headerName: "RSP",
                    },
                    {
                        field: 'buyingPrice',
                        sortable: true,
                        filter: 'agTextColumnFilter', // Enable text filter
                        filterParams: {
                            filterOptions: ['contains', 'notContains', 'startsWith', 'endsWith'], // Choose filter options
                            suppressAndOrCondition: true, // Use AND condition for multiple filters
                        },
                        maxWidth: 80,
                        headerName: "BP",
                    },
                    {
                        field: 'labourCharge',
                        sortable: true,
                        filter: 'agTextColumnFilter', // Enable text filter
                        filterParams: {
                            filterOptions: ['contains', 'notContains', 'startsWith', 'endsWith'], // Choose filter options
                            suppressAndOrCondition: true, // Use AND condition for multiple filters
                        },
                        maxWidth: 60,
                        headerName: "LC",
                    },
                    // {
                    //     field: 'total',
                    //     sortable: true,
                    //     filter: 'agTextColumnFilter', // Enable text filter
                    //     filterParams: {
                    //         filterOptions: ['contains', 'notContains', 'startsWith', 'endsWith'], // Choose filter options
                    //         suppressAndOrCondition: true, // Use AND condition for multiple filters
                    //     },
                    // },
                    {
                        field: 'make', filter: 'agTextColumnFilter', // Enable text filter
                        filterParams: {
                            filterOptions: ['contains', 'notContains', 'startsWith', 'endsWith'], // Choose filter options
                            suppressAndOrCondition: true, // Use AND condition for multiple filters
                        },
                        maxWidth: 100,
                    },
                    // { field: 'desc', headerName: 'Description' },
                    {
                        headerName: 'Action',
                        field: "_id",
                        flex: 1,
                        cellRendererFramework: (params) =>
                            <div>
                                {(params.data?.createdBy?._id === usrData.id || userRole === UserRoles.Admin || params.data?.projectManager._id === usrData.id) &&
                                    <><img src={editIcon} style={{ cursor: "pointer", height: "20px" }} onClick={() => { setIsSubproduct({ open: true, parantId: '', productId: '', _id: params?.data, mainProdQty: '' }) }} title="Edit" alt='' /></>}
                                {(userRole === UserRoles?.Admin || params.data?.createdBy?._id === usrData.id) && <img src={removeImg} onClick={() => { setDeletepopup(true); setParamsValue(params?.value) }} style={{ cursor: "pointer", height: "20px" }} className='ms-3' title="Delete" alt='' />}
                            </div>
                    }
                ],
                defaultColDef: {
                    flex: 1,
                },
            },
            getDetailRowData: (params) => {
                params.successCallback(params.data.subProduct);
            },
        };
    }, []);

    const HandleCancel = () => {
        if (editId) {
            setProductReq({
                productId: '',
                productName: '',
                productCode: '',
                reqQuantity: '',
                desc: '',
                labourCharge: '',
                retailSalePrice: '',
                buyingPrice: '',
                editedBuyingPrice: '',
                make: '',
                unit: '',
                remark: '',
                showQty: 0
            })
            setEditId('')
        } else {
            setProductReq({
                productId: '',
                productName: '',
                productCode: '',
                reqQuantity: '',
                desc: '',
                labourCharge: '',
                retailSalePrice: '',
                buyingPrice: '',
                editedBuyingPrice: '',
                make: '',
                unit: '',
                remark: '',
                showQty: 0
            })
        }
    }

    const Blur = () => {
        setRotateArrow('');
    }

    const Click = (name) => {
        if (rotateArrow === name) {
            setRotateArrow('')
        } else {
            setRotateArrow(name)
        }
    }

    useEffect(() => {
        if (productReq?.productId) {
            const product = productOption.find(item => item.value === productReq.productId);
            setSelectedProduct(product);
        } else {
            setSelectedProduct(null);
        }
    }, [productReq]);

    return (
        <Modal
            show={show}
            onHide={onHide}
            centered
            // size="xl"
            className={isSubproduct.open ? 'FinalModal' : ''}
            dialogClassName="modal-90w"
            backdrop="static"
        >
            <Modal.Header closeButton>
                <div className='d-flex final-material-modal-title w-100 justify-content-between'>
                    <Modal.Title>{"Final Material"}</Modal.Title>
                    <Button className='adduser-btn me-3' onClick={() => { setConfirmTog(true) }} disabled={isFinalMaterialConfirmedd === true}>{isFinalMaterialConfirmedd ? "Final Material Confirmed" : "Confirm Final Material"}</Button>
                </div>
            </Modal.Header>
            <Modal.Body className='final-material-modal'>
                <Row className='me-3'>
                    <Col xxl={7} xs={12}>
                        <Row>
                            <Col lg={6} xs={12}>
                                <div className="mt-3 small-input" style={{ flex: 1 }}>
                                    <div className="users-form-info ms-3 w-100">
                                        <div className="multi-select">
                                            <label>Product Name</label>
                                            {/* <div className="users-label">
                                        <div className="position-relative select-icon" > */}
                                            {/* <img src={img} className={`${rotateArrow === `priority15-51` && "rotateArrow"}`} alt="" style={{ marginTop: 2, marginRight: 4 }} /> */}
                                            {/* <select
                                                className="form-control"
                                                value={productOption?.find((item) => item?.value === productReq?.productId)?.value ?? ""}
                                                onBlur={() => Blur()}
                                                onClick={() => Click(`priority15-51`)}
                                                onChange={(e) => {
                                                    const option = productOption?.find((item) => item.value === e.target.value);
                                                    handleChange(option, 'productId');
                                                    if (option) {
                                                        setFormErrors({ ...formErrors, retailSalePrice: '', productId: '' });
                                                    }
                                                }}
                                                style={{ marginTop: "unset", paddingRight: 30 }}
                                                disabled={userRole !== UserRoles.Admin && status === "Active" ? true : false}
                                            >
                                                <option value="" style={{ display: "none" }}>Select Product</option>
                                                {productOption?.map((ele, index) => <option key={index} value={ele.value}>{ele.label}</option>)}
                                            </select> */}
                                            <Select
                                                cacheOptions
                                                placeholder="Select Product"
                                                onBlur={() => Blur()}
                                                value={selectedProduct}
                                                options={productOption}
                                                onChange={(e) => {
                                                    const option = productOption?.find((item) => item.value === e.value);
                                                    handleChange(option, 'productId');
                                                    if (option) {
                                                        setFormErrors({ ...formErrors, retailSalePrice: '', productId: '' });
                                                    }
                                                }}
                                                style={{ marginTop: "unset", paddingRight: 30 }}
                                                disabled={userRole !== UserRoles.Admin && status === "Active" ? true : false}
                                            />
                                            {/* </div>
                                    </div> */}
                                        </div>
                                        {productReq.unit && <label style={{ fontWeight: "bold", textAlign: 'end', width: '100%' }}>{productReq.unit}</label>}
                                        {formErrors?.productId && <label style={{ color: "red" }}>{formErrors.productId}</label>}
                                    </div>
                                </div>
                            </Col>
                            <Col lg={6} xs={12}>
                                <Row>
                                    <Col xs={3}>
                                        <div className="mt-3 small-input">
                                            <div className="users-form-info ms-3 w-100">
                                                <div className="users-label">
                                                    <label>Quantity</label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="Req qty"
                                                        onChange={(e) => {
                                                            handleChange(e, 'reqQuantity')

                                                        }}
                                                        value={productReq.reqQuantity}
                                                        disabled={userRole !== UserRoles.Admin && status === "Active" ? true : false}
                                                    />
                                                    {productReq.showQty ? <label>On Hand: {productReq.showQty && productReq.showQty}</label> : ''}
                                                </div>
                                                {formErrors?.reqQuantity && <label style={{ color: "red" }}>{formErrors.reqQuantity}</label>}
                                            </div>
                                        </div>
                                    </Col>
                                    <Col xs={3}>
                                        <div className="mt-3 small-input">
                                            <div className="users-form-info ms-3 w-100">
                                                <div className="users-label">
                                                    <label title='Retail Sale Price'>RSP</label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="Retail Sale price"
                                                        onChange={(e) => {
                                                            handleChange(e, 'retailSalePrice')

                                                        }}
                                                        value={productReq.retailSalePrice}
                                                    />
                                                </div>
                                                {/* {productReq.buyingPrice && <label style={{ fontWeight: "bold", textAlign: 'end', width: '100%' }} title='Buying Pr̥ice'>BP: {productReq.buyingPrice}</label>} */}
                                                {formErrors?.retailSalePrice && <label style={{ color: "red" }}>{formErrors.retailSalePrice}</label>}
                                            </div>
                                        </div>
                                    </Col>
                                    <Col xs={3}>
                                        <div className="mt-3 small-input">
                                            <div className="users-form-info ms-3 w-100">
                                                <div className="users-label">
                                                    <label title='Buying Price'>BP</label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="Buying price"
                                                        onChange={(e) => {
                                                            handleChange(e, 'buyingPrice')

                                                        }}
                                                        value={productReq.editedBuyingPrice}
                                                    />
                                                </div>
                                                {productReq.buyingPrice && <label style={{ fontWeight: "bold", textAlign: 'end', width: '100%' }} title='Buying Pr̥ice'>Actual: {productReq.buyingPrice}</label>}
                                                {/* {formErrors?.retailSalePrice && <label style={{ color: "red" }}>{formErrors.retailSalePrice}</label>} */}
                                            </div>
                                        </div>
                                    </Col>
                                    <Col xs={3}>
                                        <div className="mt-3 small-input">
                                            <div className="users-form-info ms-3 w-100">
                                                <div className="users-label">
                                                    <label title='Labour Charge'>LC</label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="labour Charge"
                                                        onChange={(e) => handleChange(e, 'labourCharge')}
                                                        value={productReq.labourCharge}
                                                    />
                                                </div>
                                                {formErrors?.labourCharge && <label style={{ color: "red" }}>{formErrors.labourCharge}</label>}
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Col>
                    {/* <Col xxl={1} lg={3} md={4} sm={6}>
                        <div className="mt-3 small-input">
                            <div className="users-form-info ms-3 w-100">
                                <div className="users-label">
                                    <label>Buying P</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Buying price"
                                        onChange={(e) => handleChange(e, 'buyingPrice')}
                                        value={productReq.buyingPrice}
                                        disabled
                                    />
                                </div>
                            </div>
                        </div>
                    </Col> */}
                    {/* <Col xl={1} lg={6} md={6}>
                        <div className="mt-3 small-input me-5 me-xl-0">
                            <div className="users-form-info ms-3 w-100">
                                <div className="multi-select">
                                    <label>Unit</label>
                                    <Select
                                        placeholder="unit"
                                        value={productReq.unit && selectValue(productReq.unit, "unit")}
                                        onChange={(e) => handleChange(e, 'unit')}
                                        options={unitOption}
                                        isDisabled={userRole !== UserRoles.Admin && status === "Active" ? true : false}
                                    />
                                </div>
                                {formErrors?.unit && <label style={{ color: "red" }}>{formErrors.unit}</label>}
                            </div>
                        </div>
                    </Col> */}
                    <Col xxl={3} lg={8} xs={12} className="pr">
                        <Row>
                            <Col xxl={7} lg={6} xs={12} className="pr">
                                <div className="mt-3 small-input">
                                    <div className="users-form-info ms-3 w-100">
                                        <div className="users-label">
                                            <label>Make</label>
                                            <textarea
                                                type="text"
                                                className="form-control"
                                                placeholder="Make"
                                                onChange={(e) => handleChange(e, 'make')}
                                                rows={1}
                                                value={productReq.make}
                                                disabled={userRole !== UserRoles.Admin && status === "Active" ? true : false}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </Col>
                            <Col xxl={5} lg={6} xs={12} className="pr">
                                <div className="mt-3 small-input">
                                    <div className="users-form-info ms-3 w-100">
                                        <div className="users-label">
                                            <label>Description</label>
                                            <textarea
                                                type="text"
                                                className="form-control"
                                                placeholder="Description"
                                                onChange={(e) => handleChange(e, 'desc')}
                                                value={productReq.desc}
                                                rows={1}
                                                disabled={userRole !== UserRoles.Admin && status === "Active" ? true : false}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Col>
                    {/* <Col xxl={2} lg={3} md={4} sm={6} className="pr">
                        <div className="mt-3 small-input">
                            <div className="users-form-info ms-3 w-100">
                                <div className="users-label">
                                    <label>Make</label>
                                    <textarea
                                        type="text"
                                        className="form-control"
                                        placeholder="Make"
                                        onChange={(e) => handleChange(e, 'make')}
                                        rows={1}
                                        value={productReq.make}
                                        disabled={userRole !== UserRoles.Admin && status === "Active" ? true : false}
                                    />
                                </div>
                            </div>
                        </div>
                    </Col>
                    <Col xxl={1} lg={3} md={4} sm={6} className="pr">
                        <div className="mt-3 small-input">
                            <div className="users-form-info ms-3 w-100">
                                <div className="users-label">
                                    <label>Description</label>
                                    <textarea
                                        type="text"
                                        className="form-control"
                                        placeholder="Description"
                                        onChange={(e) => handleChange(e, 'desc')}
                                        value={productReq.desc}
                                        rows={1}
                                        disabled={userRole !== UserRoles.Admin && status === "Active" ? true : false}
                                    />
                                </div>
                            </div>
                        </div>
                    </Col> */}
                    <Col xxl={2} lg={4} sm={12} xs={12}>
                        <div className="mt-3 small-input">
                            <div className="users-form-info ms-2 w-100">
                                <div className="users-label">
                                    <label></label>
                                    <div className="form-control border-0">
                                        <Button className='adduser-btn' onClick={() => editId ? Edit() : Save()} disabled={userRole !== UserRoles.Admin && status === "Active" ? true : false}>{editId ? 'Edit' : 'Save'}</Button>&nbsp;&nbsp;
                                        <button type='button' className='cancel-button' onClick={() => HandleCancel()} disabled={userRole !== UserRoles.Admin && status === "Active" ? true : false}>{editId ? 'Cancel' : 'Clear'}</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Col>
                    <Col xl={12} lg={12} md={12} xs={12}>
                        <div className='mb-4 mt-4 total-price-details align-items-center justify-content-between'>
                            <div>
                                <span className='title me-2'><b>Total Retail Sale Price :</b></span>
                                <span>{` ${totalRSP ?? 0} `}</span>
                            </div>
                            <div>
                                <span className='title me-2'><b>Total Buying Price :</b></span>
                                <span>{` ${totalBP ?? 0} `}</span>
                            </div>
                            <div>
                                <span className='title me-2'><b>Total Labour Charge :</b></span>
                                <span>{` ${totalLC ?? 0} `}</span>
                            </div>
                            <div>
                                <span className='title me-2'><b>Total Price :</b></span>
                                <span> {` ${total ?? 0} `}</span>
                            </div>
                            <div>
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Search..."
                                    value={search}
                                    onChange={(e) => setSearch(e.target.value.trimStart())}
                                    autoComplete="off"
                                />
                            </div>
                            {/* {getFinalMaterialByProjectData?.data?.length ? <div><span><b>{CustomHeaderComponent("Expand All")}</b></span></div> : ''} */}
                        </div>
                    </Col>

                </Row>
                <div className="ag-theme-alpine final-material-table" style={{ height: 600 }}>

                    <AgGridReact
                        className='sub-table'
                        pagination={true}
                        columnDefs={columnDefs}
                        rowModelType={"infinite"}
                        paginationPageSize={limit}
                        cacheBlockSize={limit}
                        onGridReady={onGridReady}
                        rowHeight={45}
                        defaultColDef={defaultColDef}
                        masterDetail={true}
                        detailCellRendererParams={detailCellRendererParams}
                        // detailCellRenderer={(props) => <DeatailsComponent {...props} />}
                        detailRowHeight={300}
                        onFirstDataRendered={onFirstDataRendered}
                        onSortChanged={({ api: { sortController } }) => {
                            const sortModel = sortController.getSortModel();
                            if (sortModel.length > 0) {
                                const { colId, sort } = sortModel[0];
                                setSorting({ column_name: colId, column_order: sort });
                            } else {
                                setSorting({ column_name: "", column_order: "" });
                            }
                        }}
                        gridOptions={{ noRowsOverlayComponent: 'noRowsOverlayComponent' }}
                        components={{ noRowsOverlayComponent: () => <h3>No Records Found</h3> }}
                        onCellClicked={(event) => {
                            if (((userRole === UserRoles.Admin || status !== "Active")) && (event.data?.createdBy?._id === usrData.id || userRole === UserRoles.Admin || event.data?.projectManager._id === usrData.id)) {
                                handleRowClick(event)
                            }
                        }}
                    />

                    {/* <AgGridReact
                        rowData={rowData}
                        columnDefs={columns}
                        defaultColDef={defaultColDef}
                        onGridReady={onGridReady}
                        masterDetail={true}
                        detailCellRenderer={(props) => <DeatailsComponent {...props} />}
                        detailRowHeight={300}
                        onFirstDataRendered={onFirstDataRendered}
                    /> */}

                    <div className='set-pagination-dropdaun'>
                        <p>Show</p>
                        <select onChange={(e) => setlimit(e.target.value)} id="page-size" className='mx-3 pagination-select'>
                            <option value="50">
                                50
                            </option>
                            <option value="100" selected={true}>100</option>
                            <option value="150">150</option>
                            <option value="200">200</option>
                        </select>
                        <p>Entries</p>
                    </div>
                </div>
                {isSubproduct.open && <SubProduct show={isSubproduct} onHide={() => { setIsSubproduct({ open: false, parantId: '', productId: '', _id: '', mainProdQty: '' }) }} mainProdQty={isSubproduct.mainProdQty} />}
                {deletepopup &&
                    <SweetAlert
                        danger
                        showCancel
                        confirmBtnText="Delete"
                        confirmBtnBsStyle="danger"
                        title="Are you sure?"
                        onConfirm={() => { onDelete(paramsValue) }}
                        onCancel={() => { setDeletepopup(false); setParamsValue('') }}
                        focusCancelBtn
                    >
                        You want to delete it
                    </SweetAlert>
                }
                {confirmTog &&
                    <SweetAlert
                        success
                        showCancel
                        confirmBtnText="Yes"
                        confirmBtnBsStyle="success"
                        title="Are you sure?"
                        onConfirm={() => {
                            ConfirmFinalmaterial()
                        }}
                        onCancel={() => { setConfirmTog(false) }}
                        focusCancelBtn
                    >
                        Are you sure confirm Final Material
                    </SweetAlert>
                }
            </Modal.Body>
        </Modal>
    )
}

export default FinalMaterial